import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const DetailsCellRenderer = (props: any) => {
  return (
    <div className="customCell text-center">
      <Link to={`${props.data.id}`} className="icon-button">
        <FontAwesomeIcon icon={faCirclePlus} size="lg" />
      </Link>
    </div>
  );
};

export default DetailsCellRenderer;
