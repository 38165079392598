import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useOutletContext } from "react-router-dom";
import OptionFormatter from "./OptionFormatter";
import useAxios from "../utils/useAxios";

import {
  mapProductsOptions,
  mapProductHierOptions,
  mapMaterialOptions,
  mapMoldOptions,
  mapColorOptions,
  mapGlobalProductOptions,
  mapValues,
  mapSingleValue,
  mapTccOptions,
} from "../helpers";
import {
  DebounceContext,
  DebounceContextType,
} from "../context/DebounceContext";

const PMUFilters = ({
  categories,
  subCategories,
  productLines,
  setFirstSearch,
  setFilterObject,
}: {
  categories: any;
  subCategories: any;
  productLines: any;
  setFirstSearch: any;
  setFilterObject: any;
}) => {
  const axios = useAxios();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);

  const [filteredSubCategories, setFilteredSubCategories] =
    useState<any[]>(subCategories);

  const [productSelected, setProductSelected] = useState<any | null>(null);
  const [isProductSearchByString, setIsProductSearchByString] = useState(false);
  const [categorySelected, setCategorySelected] = useState<any | null>(null);
  const [colorsSelected, setColorsSelected] = useState<any | null>(null);
  const [globalProductSelected, setGlobalProductSelected] = useState<
    any | null
  >(null);
  const [tccSelected, setTccSelected] = useState<any | null>(null);
  const [materialsSelected, setMaterialsSelected] = useState<any | null>(null);
  const [moldsSelected, setMoldsSelected] = useState<any | null>(null);
  const [nonMoldsSelected, setNonMoldsSelected] = useState<any | null>(null);
  const [subCategorySelected, setSubCategorySelected] = useState<any | null>(
    null
  );
  const [productLineSelected, setProductLineSelected] = useState<any | null>(
    null
  );
  const [inventorySelected, setInventorySelected] = useState<any | null>(null);
  const [createOptionPosition, setCreateOptionPosition] = useState<
    "last" | "first"
  >("first");

  const buttonDisabled =
    (!productSelected || productSelected.label.length < 3) &&
    !categorySelected &&
    (!colorsSelected || !colorsSelected.length) &&
    !globalProductSelected &&
    !tccSelected &&
    (!materialsSelected || !materialsSelected.length) &&
    (!moldsSelected || !moldsSelected.length) &&
    (!nonMoldsSelected || !nonMoldsSelected.length) &&
    !subCategorySelected &&
    !productLineSelected &&
    !inventorySelected
      ? true
      : false;

  const {
    colors,
    materials,
    globalProducts,
    tccs,
    molds,
    nonMolds,
    products,
    isLoading,
    filterProducts,
  } = useOutletContext<any>();

  useEffect(() => {
    if (filteredSubCategories.length === 0)
      setFilteredSubCategories(subCategories);
  }, [subCategories]);

  const handleProductChange = (e: any) => {
    if (e) {
      setProductSelected(e);
      setIsProductSearchByString(e.__isNew__);
    } else {
      setProductSelected(null);
      setIsProductSearchByString(false);
    }
  };
  const handleGlobalProductChange = (e: any) => {
    if (e) {
      setGlobalProductSelected(e);
    } else {
      setGlobalProductSelected(null);
    }
  };
  const handleTccChange = (e: any) => {
    if (e) {
      setTccSelected(e);
    } else {
      setTccSelected(null);
    }
  };
  const handleColorsChange = (e: any) => {
    if (e) {
      setColorsSelected(e);
    } else {
      setColorsSelected(null);
    }
  };
  const handleMaterialChange = (e: any) => {
    if (e) {
      setMaterialsSelected(e);
    } else {
      setMaterialsSelected(null);
    }
  };
  const handleMoldsChange = (e: any) => {
    if (e) {
      setMoldsSelected(e);
    } else {
      setMoldsSelected(null);
    }
  };
  const handleNonMoldsChange = (e: any) => {
    if (e) {
      setNonMoldsSelected(e);
    } else {
      setNonMoldsSelected(null);
    }
  };
  const handleCategoryChange = (e: any) => {
    if (e) {
      setCategorySelected(e);
      setFilteredSubCategories(
        subCategories.filter((subCateg: any) => subCateg.parent.id === e.value)
      );
    } else {
      setCategorySelected(null);
      setSubCategorySelected(null);
      setFilteredSubCategories(subCategories);
    }
  };
  const handleSubCategoryChange = (e: any) => {
    if (e) {
      setSubCategorySelected(e);
      const parentCateg =
        subCategories.find((subCateg: any) => subCateg.id === e.value)
          ?.parent ?? null;
      handleCategoryChange(mapProductHierOptions([parentCateg])?.at(0) ?? null);
    } else {
      setSubCategorySelected(null);
    }
  };
  const handleProductLineChange = (e: any) => {
    if (e) {
      setProductLineSelected(e);
    } else {
      setProductLineSelected(null);
    }
  };

  const handleInventoryChange = (e: any) => {
    if (e) {
      setInventorySelected(e);
    } else {
      setInventorySelected(null);
    }
  };

  const loadProducts = async (input: string, callback: any) => {
    let options: any = mapProductsOptions(products);
    if (input.length > 2) {
      const search = new URLSearchParams({ search: input, limit: "200" });
      const response = await axios.get(`/products/?${search}`);
      if (response.status === 200) {
        setCreateOptionPosition(
          response.data.results.length > 1 ? "first" : "last"
        );
        options = mapProductsOptions(response.data.results);
      }
    }
    callback(options);
  };

  const loadMolds = async (input: string, callback: any) => {
    let options: any = mapMoldOptions(molds);
    if (input.length > 2) {
      const search = new URLSearchParams({ search: input, limit: "200" });
      const response = await axios.get(`/molds/?${search}`);
      if (response.status === 200) {
        options = mapMoldOptions(response.data.results);
      }
    }
    callback(options);
  };
  const loadNonMolds = async (input: string, callback: any) => {
    let options: any = mapMoldOptions(nonMolds);
    if (input.length > 2) {
      const search = new URLSearchParams({ search: input, limit: "200" });
      const response = await axios.get(`/non-molded-components/?${search}`);
      if (response.status === 200) {
        options = mapMoldOptions(response.data.results);
      }
    }
    callback(options);
  };
  const loadColors = async (input: string, callback: any) => {
    let options: any = mapColorOptions(colors);
    if (input.length > 2) {
      const search = new URLSearchParams({ search: input, limit: "200" });
      const response = await axios.get(`/colors/?${search}`);
      if (response.status === 200) {
        options = mapColorOptions(response.data.results);
      }
    }
    callback(options);
  };
  const loadMaterials = async (input: string, callback: any) => {
    let options: any = mapMaterialOptions(materials);
    if (input.length > 2) {
      const search = new URLSearchParams({ search: input, limit: "200" });
      const response = await axios.get(`/materials/?${search}`);
      if (response.status === 200) {
        options = mapMaterialOptions(response.data.results);
      }
    }
    callback(options);
  };
  const loadGlobalProducts = async (input: string, callback: any) => {
    let options: any = mapGlobalProductOptions(globalProducts);
    if (input.length > 2) {
      const search = new URLSearchParams({ search: input, limit: "100" });
      const response = await axios.get(`/global-products/?${search}`);
      if (response.status === 200) {
        options = mapGlobalProductOptions(response.data.results);
      }
    }
    callback(options);
  };
  const loadTccs = async (input: string, callback: any) => {
    let options: any = mapTccOptions(tccs);
    if (input.length > 2) {
      const search = new URLSearchParams({ search: input, limit: "100" });
      const response = await axios.get(`/tccs/?${search}`);
      if (response.status === 200) {
        options = mapTccOptions(response.data.results);
      }
    }
    callback(options);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setFirstSearch(false);
    const filterObject = isProductSearchByString
      ? {
          search: mapSingleValue(productSelected),
          molds: mapValues(moldsSelected),
          nonMolds: mapValues(nonMoldsSelected),
          colors: mapValues(colorsSelected),
          materials: mapValues(materialsSelected),
          category_id: mapSingleValue(categorySelected),
          sub_category_id: mapSingleValue(subCategorySelected),
          product_line_id: mapSingleValue(productLineSelected),
          global_product: globalProductSelected
            ? [globalProductSelected.value.id.toString()]
            : null,
          tcc: tccSelected ? [tccSelected.value.id.toString()] : null,
          inventory: mapSingleValue(inventorySelected),
        }
      : {
          id: mapSingleValue(productSelected),
          molds: mapValues(moldsSelected),
          nonMolds: mapValues(nonMoldsSelected),
          colors: mapValues(colorsSelected),
          materials: mapValues(materialsSelected),
          category_id: mapSingleValue(categorySelected),
          sub_category_id: mapSingleValue(subCategorySelected),
          product_line_id: mapSingleValue(productLineSelected),
          global_product: globalProductSelected
            ? [globalProductSelected.value.id.toString()]
            : null,
          tcc: tccSelected ? [tccSelected.value.id.toString()] : null,
          inventory: mapSingleValue(inventorySelected),
        };
    setFilterObject(filterObject);
    filterProducts(filterObject);
  };

  return (
    <div className="py-3 mb-2">
      <form className="gx-3 gy-2" onSubmit={handleSubmit}>
        <div className="row form-row align-items-end">
          <div className="col-lg form-group">
            <label className="form-label">Category</label>
            <Select
              options={mapProductHierOptions(categories)}
              onChange={handleCategoryChange}
              formatOptionLabel={OptionFormatter}
              value={categorySelected}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">Sub-Category</label>
            <Select
              options={mapProductHierOptions(filteredSubCategories)}
              onChange={handleSubCategoryChange}
              formatOptionLabel={OptionFormatter}
              value={subCategorySelected}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">Product Line</label>
            <Select
              options={mapProductHierOptions(productLines)}
              onChange={handleProductLineChange}
              value={productLineSelected}
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row">
          <div className="col-lg form-group">
            <label className="form-label">Global Product Code / Name</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadGlobalProducts,
                  input,
                  mapGlobalProductOptions(globalProducts) ?? [],
                  callback
                );
              }}
              defaultOptions={mapGlobalProductOptions(globalProducts)}
              onChange={handleGlobalProductChange}
              value={globalProductSelected}
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              placeholder="(min 3 characters)"
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">TCC Code / Name</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(loadTccs, input, mapTccOptions(tccs), callback);
              }}
              defaultOptions={mapTccOptions(tccs)}
              onChange={handleTccChange}
              value={tccSelected}
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              placeholder="(min 3 characters)"
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row">
          <div className="col-lg-4 form-group">
            <label className="form-label">SKUs (nº / Description)</label>
            <div className="d-flex align-items-center">
              <span className="mr-2">11 </span>
              <AsyncCreatableSelect
                loadOptions={(input, callback) => {
                  searchDebounce(
                    loadProducts,
                    input,
                    mapProductsOptions(products) ?? [],
                    callback
                  );
                }}
                defaultOptions={mapProductsOptions(products)}
                onChange={handleProductChange}
                value={productSelected}
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                placeholder="(min 3 characters)"
                isClearable
                createOptionPosition={createOptionPosition}
                formatCreateLabel={(inputValue: string) =>
                  `Search for "${inputValue}"`
                }
                isLoading={isLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
                className="flex-fill"
              />
            </div>
          </div>

          <div className="col-lg-4 form-group">
            <label className="form-label">
              Molded Components (nº / Description)
            </label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadMolds,
                  input,
                  mapMoldOptions(molds) ?? [],
                  callback
                );
              }}
              defaultOptions={mapMoldOptions(molds)}
              onChange={handleMoldsChange}
              value={moldsSelected}
              formatOptionLabel={OptionFormatter}
              isOptionDisabled={() =>
                moldsSelected && moldsSelected.length >= 10
              }
              isMulti
              classNamePrefix="react-select"
              placeholder="(min 3 characters, up to 10 molded components)"
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg-4 form-group">
            <label className="form-label">
              Non-Molded Components (nº / Description)
            </label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadNonMolds,
                  input,
                  mapMoldOptions(nonMolds) ?? [],
                  callback
                );
              }}
              defaultOptions={mapMoldOptions(nonMolds)}
              onChange={handleNonMoldsChange}
              value={nonMoldsSelected}
              formatOptionLabel={OptionFormatter}
              isOptionDisabled={() =>
                nonMoldsSelected && nonMoldsSelected.length >= 10
              }
              isMulti
              classNamePrefix="react-select"
              placeholder="(min 3 characters, up to 10 non-molded components)"
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row align-items-end">
          <div className="col-lg-4 form-group">
            <label className="form-label">Material (Code / Name)</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadMaterials,
                  input,
                  mapMaterialOptions(materials) ?? [],
                  callback
                );
              }}
              defaultOptions={mapMaterialOptions(materials)}
              onChange={handleMaterialChange}
              formatOptionLabel={OptionFormatter}
              isOptionDisabled={() =>
                materialsSelected && materialsSelected.length >= 5
              }
              isMulti
              value={materialsSelected}
              classNamePrefix="react-select"
              placeholder="(min 3 characters, up to 5 materials)"
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg-4 form-group">
            <label className="form-label">Colors (Code / Name)</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadColors,
                  input,
                  mapColorOptions(colors) ?? [],
                  callback
                );
              }}
              defaultOptions={mapColorOptions(colors)}
              onChange={handleColorsChange}
              formatOptionLabel={OptionFormatter}
              isOptionDisabled={() =>
                colorsSelected && colorsSelected.length >= 5
              }
              isMulti
              value={colorsSelected}
              classNamePrefix="react-select"
              placeholder="(min 3 characters, up to 5 colors)"
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg-3 form-group inventory-col">
            <label className="form-label">Inventory?</label>
            <Select
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              onChange={handleInventoryChange}
              value={inventorySelected}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>

          <div className="col-lg-1 form-group">
            <input
              disabled={buttonDisabled}
              type="submit"
              className="btn btn-primary btn-block"
              value="Search"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default PMUFilters;
