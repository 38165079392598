import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const ChangesCellRenderer = ({ node }: { node: any }) => {
  const { last_change } = node.data;
  return (
    <>
      {last_change ? (
        <div>
          Yes
          <span className="tooltip-span">
            <FontAwesomeIcon icon={faCircleInfo} className="tooltip-icon" />
          </span>
        </div>
      ) : (
        <div>None</div>
      )}
    </>
  );
};

export default ChangesCellRenderer;
