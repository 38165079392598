import Spinner from "../components/Spinner";
import ApprovalQueuesTable from "../components/ApprovalQueuesTable";
import ApprovalDetails from "../components/ApprovalDetails";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import useAxios from "../utils/useAxios";

const ApprovalsPage = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const axios = useAxios();

  const { queueId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [approvalQueues, setApprovalQueues] = useState<any[]>([]);
  const [floatingFilter, setFloatingFilter] = useState(null);

  const fetchQueuesAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("queues/?limit=1000");
      if (response.status === 200) {
        setApprovalQueues(response.data.results);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!hasPermission("api.view_pmuqueue")) return navigate("/no-access");
    fetchQueuesAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3 d-flex justify-content-between align-items-center">
        <h5 className="my-1 text-primary">Approval Queues</h5>
      </div>
      <div className="card-body">
        {isLoading ? (
          <Spinner />
        ) : queueId ? (
          <ApprovalDetails queueId={queueId} />
        ) : (
          <ApprovalQueuesTable
            data={approvalQueues}
            refreshData={fetchQueuesAsync}
            floatingFilter={floatingFilter}
            setFloatingFilter={setFloatingFilter}
          />
        )}
      </div>
    </div>
  );
};

export default ApprovalsPage;
