import { useState } from "react";

const CheckBox = ({ selectAll, api }: any) => {
  const [isChecked, setChecked] = useState(false);
  
  api.addEventListener('filterChanged', () => {
    setChecked(false);
  })

  const handleCheck = (value: boolean) => {
    selectAll(value);
    setChecked(value);
  }

  return (
    <input
      className="checkbox"
      checked={isChecked}
      onChange={(e) => handleCheck(e.target.checked)}
      type="checkbox"
    />
  );
};

export default CheckBox;
