// --- mapping data array to option array for select
export const mapProductsOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, name } = item;
      return {
        value: id,
        label: id + " - " + name,
      };
    });
  }
};

export const mapParentCodeOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { parent_code } = item;
      return {
        value: parent_code,
        label: parent_code,
      };
    });
  }
};

export const mapProductHierOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, code, name, active } = item;
      return {
        value: id,
        label: active
          ? code + " - " + name
          : code + " - " + name + " (inactive)",
      };
    });
  }
};

export const mapActiveProductHierOptions = (array: any[]) => {
  if (array) {
    return array
      .filter((item) => item.active)
      .map((item) => {
        const { id, code, name, active } = item;
        return {
          value: id,
          label: active
            ? code + " - " + name
            : code + " - " + name + " (inactive)",
        };
      });
  }
};

export const mapColorOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, name } = item;
      return {
        value: id,
        label: id + " - " + name,
      };
    });
  }
};

export const mapMaterialOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, name, four_digit_code } = item;
      return {
        value: id,
        label: four_digit_code + " - " + name,
      };
    });
  }
};

export const mapGlobalProductOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      return {
        value: item,
        label: `${getGlobalProductCode(item)} - ${item.name}`,
      };
    });
  }
};

export const getGlobalProductCode = (globalProduct: any) => {
  return `${globalProduct?.sub_category?.parent?.code}${globalProduct?.sub_category.code}${globalProduct?.product_line.code}${globalProduct?.suffix}`;
};

export const mapTccOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      return {
        value: item,
        label: `${getTccCode(item)} - ${item.name}`,
      };
    });
  } else {
    return [];
  }
};

export const getTccCode = (tcc: any) => {
  return `${tcc.global_product.sub_category?.parent?.code}${tcc.global_product.sub_category.code}${tcc.global_product.product_line.code}${tcc.global_product.suffix}-${tcc.suffix}`;
};

export const mapMoldOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, name } = item;
      return {
        value: id,
        label: id + " - " + name,
      };
    });
  }
};

//  returns value array from multiselect
export const mapValues = (
  options: [{ label: string; value: string }] | null
) => {
  if (options) {
    return options.map((option) => option.value);
  } else return null;
};

// returns value from select-option object
export const mapSingleValue = (
  option: { label: string; value: string } | null
) => {
  if (option) {
    return [option.value];
  } else return null;
};

// turns product filter object to query string for product-details enpoint
export const mapFiltersToQueryString = (filters: any) => {
  const keys = Object.keys(filters);
  let strings: string[] = [];
  keys.forEach((key) => {
    if (filters[key] && filters[key].length) {
      let ids: string[] = [];
      filters[key].forEach((item: any) => {
        ids.push(item);
      });
      strings.push(key + "=" + ids.join(","));
    }
  });
  const query = strings.join("&");
  return query;
};

// default year for admin screen
export const currentYear = new Date().getFullYear();

// default year for admin screen
export const mapYearOptions = () => {
  const years = [
    2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,
    2033, 3034, 3035,
  ];
  const currentyear = new Date().getFullYear();
  return years
    .filter((year: number) => year <= currentyear)
    .map((year) => {
      return { value: year, label: year };
    });
};

// value getter for new Value in admin details
export const getNewValuesArray = (data: any) => {
  let changes = [];
  if (data.sub_category) {
    changes.push("Category: " + data.category);
    changes.push("Sub-Category: " + data.sub_category);
  }
  if (data.product_line) {
    changes.push("Product Line: " + data.product_line);
  }
  if (data.parent_code) {
    changes.push("Parent Code: " + data.parent_code);
  }
  if (data.gp_name) {
    changes.push("Global Product Name: " + data.gp_name);
  }
  if (data.changes.includes("Global Product") && data.global_product) {
    changes.push("Global Product: " + data.global_product);
  }
  if (data.tcc) {
    changes.push("TCC: " + data.tcc);
  }
  return changes;
};

// value getter for changes field in approval tables
export const getChangesValue = (data: any) => {
  return data.changes;
};

// value Formatter for date field in admin tables
export const formatDateValue = (data: any) => {
  return data
    ? new Date(data)
        .toLocaleString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        .replace("AM", "a.m.")
        .replace("PM", "p.m.")
    : "";
};
