import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import Spinner from "./Spinner";

import useAxios from "../utils/useAxios";
import { IMold } from "../types/data.interface";

const MoldCompModal = ({
  handleCloseModal,
  product,
}: {
  handleCloseModal: any;
  product: any;
}) => {
  const axios = useAxios();

  const [molds, setMolds] = useState<any[]>([]);
  const [nonMolds, setNonMolds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchComponentsAsync = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/product-details/${product.id}/molds/?limit=500`
      );
      const sortedComponents = response.data.results.sort(function (
        a: any,
        b: any
      ) {
        return a.id - b.id;
      });
      setMolds(
        sortedComponents.filter(
          (component: any) => component.compo_type === "B"
        )
      );
      setNonMolds(
        sortedComponents.filter(
          (component: any) => component.compo_type === "P"
        )
      );
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchComponentsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between mb-2">
        <div></div>
        <h3 className="h5 mb-2">
          <strong>{`Composition Details - SKU nº 11 ${product.id} - ${product.name}`}</strong>
        </h3>
        <button onClick={handleCloseModal} className="icon-button close-cross">
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {molds.length > 0 && (
            <>
              <h5 className="h6 mb-2">Molded Components</h5>
              <div className="molds-table-container">
                <table className="table table-striped table-bordered mb-1">
                  <thead>
                    <tr>
                      <th scope="col" style={{ minWidth: 105 }}>
                        <span className="d-flex justify-between">
                          Mold n°<i className="fa fa-sort-down ml-auto"></i>
                        </span>
                      </th>
                      <th style={{ minWidth: 200 }} scope="col">
                        Description
                      </th>
                      <th style={{ maxWidth: 80 }} scope="col">
                        N° of pieces
                      </th>
                      <th style={{ minWidth: 200 }} scope="col">
                        Material
                      </th>
                      <th style={{ minWidth: 200 }} scope="col">
                        Color
                      </th>
                      <th style={{ maxWidth: 90 }} scope="col">
                        Printed Included
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr></tr>
                    {molds &&
                      molds.map((mold: IMold, index) => (
                        <tr key={mold.id + index}>
                          <td>{mold.id}</td>
                          <td>{mold.name}</td>
                          <td>{mold.nr_pieces}</td>
                          <td>{mold.material}</td>
                          <td>{mold.color}</td>
                          <td>{mold.printed ? "Yes" : "No"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <small>Total entries: {molds.length}</small>
            </>
          )}

          {molds.length > 0 && nonMolds.length > 0 && (
            <div className="mb-3"></div>
          )}

          {nonMolds.length > 0 && (
            <>
              <h5 className="h6 mb-2">Non-Molded Components</h5>
              <div className="molds-table-container">
                <table className="table table-striped table-bordered mb-1">
                  <thead>
                    <tr>
                      <th scope="col" style={{ minWidth: 130 }}>
                        <span className="d-flex justify-between">
                          Sequence n°<i className="fa fa-sort-down ml-auto"></i>
                        </span>
                      </th>
                      <th style={{ minWidth: 200 }} scope="col">
                        Description
                      </th>
                      <th style={{ maxWidth: 80 }} scope="col">
                        N° of pieces
                      </th>
                      <th style={{ minWidth: 200 }} scope="col">
                        Material
                      </th>
                      <th style={{ minWidth: 200 }} scope="col">
                        Color
                      </th>
                      <th style={{ maxWidth: 90 }} scope="col">
                        Printed Included
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr></tr>
                    {nonMolds &&
                      nonMolds.map((nonMold: IMold, index) => (
                        <tr key={nonMold.id + index}>
                          <td>{nonMold.id}</td>
                          <td>{nonMold.name}</td>
                          <td>{nonMold.nr_pieces}</td>
                          <td>{nonMold.material}</td>
                          <td>{nonMold.color}</td>
                          <td>{nonMold.printed ? "Yes" : "No"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <small>Total entries: {nonMolds.length}</small>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MoldCompModal;
