import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import NotificationPanel from "../NotificationPanel";

const TopNav = ({ title, toggle }: { title: string; toggle: any }) => {
  const authCtx = useContext(AuthContext);
  const username = authCtx?.username;

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">
      {/* Sidebar Toggle (Topbar) */}
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
        onClick={toggle}
      >
        <i className="fa fa-bars"></i>
      </button>

      <h5 className="text-primary">{title}</h5>

      {/* Topbar Navbar */}
      <ul className="navbar-nav ml-auto">
        {/* Nav Item - Alerts */}
        <NotificationPanel />
        <div className="topbar-divider d-none d-sm-block"></div>
        {/* Nav Item - User Information */}
        <li className="nav-item dropdown no-arrow">
          <div className="nav-link dropdown-toggle">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
              {username ? username : ""}
            </span>
            <img
              className="img-profile rounded-circle"
              src="https://i.pinimg.com/736x/31/ec/2c/31ec2ce212492e600b8de27f38846ed7.jpg"
              alt="User"
            />
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default TopNav;
