import Notification from "./Notification";
import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import NotificationContext from "../context/NotificationContext";

const NotificationPanel = () => {
  const notificationCtx = useContext(NotificationContext);
  const notifications = notificationCtx?.notifications;
  const fetchNotifications = notificationCtx?.fetchNotifications;
  const markAllAsRead = notificationCtx?.markAllAsRead;
  const location = useLocation();

  const clearAll = async () => {
    markAllAsRead();
  };
  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications?.length, location]);

  return (
    <li className="nav-item dropdown no-arrow mx-1">
      <div
        className="nav-link dropdown-toggle"
        id="alertsDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={fetchNotifications}
      >
        <i className="fas fa-bell fa-fw"></i>
        {/* Counter - Alerts */}
        {notifications && notifications.length > 0 && (
          <span className="badge badge-danger badge-counter">
            {notifications.length}
          </span>
        )}
      </div>
      {/* Dropdown - Alerts */}
      <div
        className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
        aria-labelledby="alertsDropdown"
      >
        <h6 className="dropdown-header">Alerts Center</h6>
        {notifications && notifications.length > 0 ? (
          <>
            <div className="notification-container">
              {notifications.map((item: any) => (
                <Notification notification={item} key={item.id} />
              ))}
            </div>
            <div className="dropdown-footer">
              <button
                className="dropdown-item text-center small text-gray-600"
                onClick={clearAll}
              >
                Clear All
              </button>
            </div>
          </>
        ) : (
          <p className="text-center my-3 text-gray-600">No Notifications</p>
        )}
      </div>
    </li>
  );
};

export default NotificationPanel;
