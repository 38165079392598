import { useState, useRef, useMemo, useEffect } from "react";
import PMUTable from "../components/PMUTable";
import PMUFilters from "../components/PMUFilters";
import MassUpdateModal from "../components/MassUpdateModal";
import { AgGridReact } from "ag-grid-react";

import ReactModal from "react-modal";
import { useOutletContext } from "react-router-dom";

const PMUPage = () => {
  const gridRef = useRef<AgGridReact>(null);
  const [replaceButtonDisabled, setReplaceButtonDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const getSelectedProducts = () => {
    return gridRef.current ? gridRef.current.api.getSelectedRows() : [];
  };
  const onSelectionChanged = () => {
    setReplaceButtonDisabled(!gridRef.current?.api?.getSelectedRows().length);
    setSelectedRows(getSelectedProducts());
  };

  const { dataCatalog, globalProducts, tccs, filteredProducts } =
    useOutletContext<any>();
  const categories = dataCatalog.filter(
    (item: any) => item.type === "Category"
  );
  const subCategories = dataCatalog.filter(
    (item: any) => item.type === "Sub-Category"
  );
  const productLines = dataCatalog.filter(
    (item: any) => item.type === "Product Line"
  );

  const [firstSearch, setFirstSearch] = useState(true);
  const [filterObject, setFilterObject] = useState(null);

  const isFirstSearch: boolean = useMemo(() => firstSearch, [firstSearch]);

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    setSelectedRows([]);
    setReplaceButtonDisabled(true);
  }, [filteredProducts]);

  return (
    <>
      <ReactModal
        isOpen={showModal}
        className="custom-modal mass-update"
        overlayClassName="custom-overlay"
      >
        <MassUpdateModal
          gridRef={gridRef}
          selectedRows={selectedRows}
          handleCloseModal={closeModal}
          categories={categories}
          subCategories={subCategories}
          productLines={productLines}
          globalProducts={globalProducts}
          tccs={tccs}
        />
      </ReactModal>
      <PMUFilters
        categories={categories}
        subCategories={subCategories}
        productLines={productLines}
        setFirstSearch={setFirstSearch}
        setFilterObject={setFilterObject}
      />
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 text-primary">Search Results</h6>
        </div>
        <div className="card-body">
          <PMUTable
            gridRef={gridRef}
            onSelectionChanged={onSelectionChanged}
            isFirstSearch={isFirstSearch}
            filterObject={filterObject}
          />
          <button
            disabled={replaceButtonDisabled}
            onClick={openModal}
            className="btn btn-primary"
          >
            Replace
          </button>
        </div>
      </div>
    </>
  );
};

export default PMUPage;
