
const NotFound = () => {
  return (
      <div className="d-flex justify-content-center">
        <h5 className="my-5">Page Not Found</h5>
      </div>
  );
};

export default NotFound;
