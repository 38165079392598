const UserGuideBase = () => {
  return (
    <div className="guide">
      <h1>User Guide</h1>
      <div className="table-of-content">
        <ul className="toc-indentation">
          <li>
            <a href="#UserManual-TW-MDM-PMU(Requesterrole)-Authentication">
              Authentication
            </a>
          </li>
          <li>
            <a href="#UserManual-TW-MDM-PMU(Requesterrole)-Requesterpermissions">
              Requester permissions
            </a>
          </li>
          <li>
            <a href="#UserManual-TW-MDM-PMU(Requesterrole)-Search">Search</a>
            <ul className="toc-indentation">
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-Multi-criteriaauto-completesearchfield">
                  Multi-criteria auto-complete search field
                </a>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-SearchResultsTable">
                  Search Results Table
                </a>
                <ul className="toc-indentation">
                  <li>
                    <a href="#UserManual-TW-MDM-PMU(Requesterrole)-MoldsCompositionDetailspop-up">
                      Molds Composition Details pop-up
                    </a>
                  </li>
                  <li>
                    <a href="#UserManual-TW-MDM-PMU(Requesterrole)-Changestooltip">
                      Changes tooltip
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-ResultsFiltering">
                  Results Filtering
                </a>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-SelectResults">
                  Select Results
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#UserManual-TW-MDM-PMU(Requesterrole)-Replace/Update">
              Replace / Update
            </a>
            <ul className="toc-indentation">
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-Updatewidget">
                  Update widget
                </a>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-Updateworkflow">
                  Update workflow
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#UserManual-TW-MDM-PMU(Requesterrole)-ApprovalQueues">
              Approval Queues
            </a>
            <ul className="toc-indentation">
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-ApprovalQueues-MainScreen">
                  Approval Queues - Main Screen
                </a>
                <ul className="toc-indentation">
                  <li>
                    <a href="#UserManual-TW-MDM-PMU(Requesterrole)-AdvancedFiltering">
                      Advanced Filtering
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-RequestDetails">
                  Request Details
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#UserManual-TW-MDM-PMU(Requesterrole)-AdminLogs">
              Admin Logs
            </a>
            <ul className="toc-indentation">
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-AdminLogs-Mainscreen">
                  Admin Logs - Main screen
                </a>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-AdvancedFiltering.1">
                  Advanced Filtering
                </a>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-PMU(Requesterrole)-AdminLogDetails">
                  Admin Log Details
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <h2 id="UserManual-TW-MDM-PMU(Requesterrole)-Authentication">
        Authentication
      </h2>
      <p>
        Authentication should be done through the
        <strong> Mold Applications </strong>&gt;
        <strong> Item Master - Main Menu, </strong>on the right side of the
        existing Product Master hyperlink. Clicking once in the Product Mass
        Update hyperlink will start the authentication process. When this
        process ends, a new tab will open redirecting to the Product Mass Update
        application.
      </p>
      <p>
        The access to <strong>Product Mass Update </strong>is only available for
        a restricted group of users. Two types of roles are defined:
        <strong> Requester </strong>(limited permissions) and
        <strong> Approver </strong>(all permissions).
      </p>
      <img
        className="image image-medium"
        alt="Product Mass Update link"
        src={process.env.PUBLIC_URL + "/userguide/46792728.png"}
      />
      <span className="image-caption">Product Mass Update link</span>

      <h2 id="UserManual-TW-MDM-PMU(Requesterrole)-Requesterpermissions">
        Requester permissions
      </h2>
      <p>
        Users associated with the Requester role have the following permissions:
      </p>
      <ul>
        <li>
          <p>Search for products and select products to be replaced</p>
        </li>
        <li>
          <p>Request an update (need Approver decision)</p>
        </li>
        <li>
          <p>
            See Approval Queues and Request Details (but can’t perform any
            Approve/Reject action)
          </p>
        </li>
        <li>
          <p>See Admin Logs and Logs Details</p>
        </li>
        <li>
          <p>Receive notifications</p>
        </li>
      </ul>
      <h2 id="UserManual-TW-MDM-PMU(Requesterrole)-Search">Search</h2>
      <p>
        Products can be searched using multiple criteria by filling some or all
        of the following fields:
      </p>
      <ul>
        <li>
          auto-complete search field, with the possibility to search for one
          specific product or search for all products containing a specific
          string of characters: <em>Products</em>
        </li>
        <li>
          multi-criteria auto-complete search field (“AND” query, up to 5
          criteria): <em>Mold Composition, Material and Color</em>
          <ul>
            <li>
              ex.: for searching Mold nº 7, type “7 -“; for Mold nº 13, type “13
              “
            </li>
          </ul>
        </li>
        <li>
          simple auto-complete search field:{" "}
          <em>Category, Sub-Category, Product Line </em>and{" "}
          <em>Global Product Name</em>;
        </li>
        <li>
          simple dropdown list with yes/no choice: <em>Inventory?</em>;
        </li>
      </ul>
      <p>
        and clicking the <strong>Search</strong> button on the right. Products
        returned in the Results table are the ones matching with all criteria.
      </p>
      <p>
        By default, the <strong>Search </strong>button is disabled until at
        least one field is filled.
      </p>
      <img
        className="image"
        alt="Search Fields"
        src={process.env.PUBLIC_URL + "/userguide/46956853.png"}
      />
      <span className="image-caption">Search Fields</span>
      <img
        alt="Search Fields"
        className="image"
        src={process.env.PUBLIC_URL + "/userguide/search-for-option.png"}
      />
      <span className="image-caption">"Search for" option</span>

      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-Multi-criteriaauto-completesearchfield">
        Multi-criteria auto-complete search field
      </h3>
      <img
        className="image"
        alt="Limit of 5 criteria in the Mold Composition search field"
        src={process.env.PUBLIC_URL + "/userguide/46956865.png"}
      />
      <span className="image-caption">
        Limit of 5 criteria in the Mold Composition search field
      </span>

      <p>
        Criteria can be removed in the fields, one-by-one by clicking on the “x”
        next to the criteria, or all by clicking on the right end side of the
        field close to the arrow icon.
      </p>

      <img
        className="image"
        alt="Ways to remove one or all criteria in the search field"
        src={process.env.PUBLIC_URL + "/userguide/46956859.png"}
      />
      <span className="image-caption">
        Ways to remove one or all criteria in the search field
      </span>

      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-SearchResultsTable">
        Search Results Table
      </h3>
      <p>
        Results are returned from the search criteria applied previously,
        matching all criteria (“AND” query considering all criteria filled).
      </p>
      <p>
        If no results match the search criteria, the message “No results found
        with these criteria” appear in the results table.
      </p>

      <img
        className="image"
        alt="“No results found with these criteria” message"
        src={process.env.PUBLIC_URL + "/userguide/46956889.png"}
      />
      <span className="image-caption">
        “No results found with these criteria” message
      </span>

      <p>
        If results are returned, the following information is displayed in the
        table:
      </p>
      <ul>
        <li>
          <p>
            <strong>Product Information</strong>: Product Number, Parent Code,
            Category, Sub-Category, Product Line, Global Product Name,
            Description
          </p>
        </li>
        <li>
          <p>
            <strong>Mold Composition</strong>:
          </p>
          <ul>
            <li>
              <p>
                <strong>Molds quantity</strong>: how many molds compose the
                product
              </p>
            </li>
            <li>
              <p>
                By clicking on the “<strong>+ Details</strong>” button, the
                <strong> Molds Composition Details pop-up</strong> opens
                displaying more information about the molds such as mold number
                and description, number of pieces, color and material, and if
                there is any printing (license) included.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>Changes tooltip</strong> appears if a change has been
            performed on the product information through the PMU use, and
            displays the last change performed on the product, on which date and
            by which user.
          </p>
        </li>
        <li>
          <p>
            <strong>Inventory</strong>: <em>yes</em>, if there is inventory,{" "}
            <em>no</em> if there is no inventory.
          </p>
        </li>
        <li>
          <p>
            “<strong>Select</strong>” column: allows to select some or all items
            in the table by ticking the checkbox.
          </p>
        </li>
      </ul>
      <p>
        All columns headers are sortable and advanced filtering can be applied.
      </p>
      <p>
        The <strong>refresh</strong> button allows the user to refresh the
        result, keeping the same search criteria, to display any changes
        applied.
      </p>
      <img
        className="image"
        alt="Search results table"
        src={process.env.PUBLIC_URL + "/userguide/47349797.png"}
      />
      <span className="image-caption">Search results table</span>
      <h4 id="UserManual-TW-MDM-PMU(Requesterrole)-MoldsCompositionDetailspop-up">
        Molds Composition Details pop-up
      </h4>
      <img
        className="image"
        alt="Molds Composition Details pop-up"
        src={process.env.PUBLIC_URL + "/userguide/46956901.png"}
      />
      <span className="image-caption">Molds Composition Details pop-up</span>

      <h4 id="UserManual-TW-MDM-PMU(Requesterrole)-Changestooltip">
        Changes tooltip
      </h4>

      <img
        className="image"
        alt="Changes tooltip if new values were applied and no previous values were existing"
        src={process.env.PUBLIC_URL + "/userguide/46956907.png"}
      />
      <span className="image-caption">
        Changes tooltip if new values were applied and no previous values were
        existing
      </span>

      <img
        className="image"
        alt="Changes tooltip if previous values were already existing and were substituted"
        src={process.env.PUBLIC_URL + "/userguide/46956913.png"}
      />
      <span className="image-caption">
        Changes tooltip if previous values were already existing and were
        substituted
      </span>

      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-ResultsFiltering">
        Results Filtering
      </h3>
      <p>
        After clicking on search button, in the Results table, it's also
        possible to filter data by filling the search input on the top-right of
        the table with text, by sorting the data using the arrows in the table
        headers, or by using the advanced filtering in the table headers. In the
        last option, all filters can be cleaned by clicking on the “clean
        filter” icon.
      </p>

      <img
        className="image"
        alt="Search bar and advanced filtering options + clean filter icon"
        src={process.env.PUBLIC_URL + "/userguide/47349836.png"}
      />
      <span className="image-caption">
        Search bar and advanced filtering options + clean filter icon
      </span>

      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-SelectResults">
        Select Results
      </h3>
      <img
        className="image"
        alt="Individual row selection"
        src={process.env.PUBLIC_URL + "/userguide/47349842.png"}
      />
      <span className="image-caption">Individual row selection</span>

      <img
        className="image"
        alt="Select all"
        src={process.env.PUBLIC_URL + "/userguide/47349848.png"}
      />
      <span className="image-caption">Select all</span>

      <h2 id="UserManual-TW-MDM-PMU(Requesterrole)-Replace/Update">
        Replace / Update
      </h2>
      <p>
        To start the replace process, and change the
        <em>Category, Sub-Category, Product Line, Global Product Name </em>
        and/or <em>Parent Code</em> values for one or various products at the
        same time, first select the products by ticking individually the
        checkbox on the right end of each product row or by using the “
        <strong>Select all</strong>” checkbox. The <strong>Replace </strong>
        button is only available if at least one product is selected.
      </p>

      <img
        className="image"
        alt="Replace button in disabled when no product is selected"
        src={process.env.PUBLIC_URL + "/userguide/47349854.png"}
      />
      <span className="image-caption">
        Replace button in disabled when no product is selected
      </span>
      <img
        className="image"
        alt="Replace button in enabled when at least one product is selected"
        src={process.env.PUBLIC_URL + "/userguide/47349860.png"}
      />
      <span className="image-caption">
        Replace button in enabled when at least one product is selected
      </span>

      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-Updatewidget">
        Update widget
      </h3>
      <p>
        When clicking on the <strong>Replace </strong>button, the
        <strong> update widget</strong> appears to define the new values for one
        or more of the following fields:
        <em>Category, Sub-Category, Product Line, Global Product Name </em>
        and/or <em>Parent Code</em>.
      </p>
      <p>
        Only fields to be changed need to be filled; if the field is left empty,
        without any value selected or filled, the current value will be
        maintained and remain unchanged. When at least one field is filled with
        a new value, the <strong>Update </strong>button becomes available and
        the new value(s) will be applied on the selected items.
      </p>

      <img
        className="image"
        alt="Update button is disabled when no field is filled"
        src={process.env.PUBLIC_URL + "/userguide/47349866.png"}
      />
      <span className="image-caption">
        Update button is disabled when no field is filled
      </span>

      <img
        className="image"
        alt="Update button is disabled when no sub-category is filled when a category is selected"
        src={process.env.PUBLIC_URL + "/userguide/47349872.png"}
      />
      <span className="image-caption">
        Update button is disabled when no sub-category is filled when a category
        is selected
      </span>

      <img
        className="image"
        alt="Update button is enabled when Category and Sub-Category fields are filled
"
        src={process.env.PUBLIC_URL + "/userguide/47349878.png"}
      />
      <span className="image-caption">
        Update button is enabled when Category and Sub-Category fields are
        filled
      </span>

      <img
        className="image"
        alt="Update button is enabled when at least one of the Product Line, Parent Code and/or Global Product Name field is filled"
        src={process.env.PUBLIC_URL + "/userguide/47349884.png"}
      />
      <span className="image-caption">
        Update button is enabled when at least one of the Product Line, Parent
        Code and/or Global Product Name field is filled
      </span>

      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-Updateworkflow">
        Update workflow
      </h3>
      <p>
        The workflow after clicking on the <strong>Update </strong>button will
        depend on the user role (Requester or Approver) and is detailed below.
      </p>

      <img
        className="image image-medium"
        alt="PMU Approval Queues, Update Request and Admin Logs process"
        src={process.env.PUBLIC_URL + "/userguide/46792848.png"}
      />
      <span className="image-caption">
        PMU Approval Queues, Update Request and Admin Logs process
      </span>

      <p>
        After clicking on the <strong>Update </strong>button, a confirmation
        pop-up informs the user the update process has been sent to the Approval
        Queue and is pending on the Approver decision.
      </p>

      <img
        className="image image-medium"
        alt="Pending request confirmation pop-up"
        src={process.env.PUBLIC_URL + "/userguide/46792860.png"}
      />
      <span className="image-caption">Pending request confirmation pop-up</span>

      <p>
        The user will be notified about the approver decision, if the request
        has been <em>approved </em>or <em>rejected.</em>
      </p>

      <img
        className="image image-small"
        alt="“Approved” notification"
        src={process.env.PUBLIC_URL + "/userguide/47251528.png"}
      />
      <span className="image-caption">“Rejected” notification</span>

      <img
        className="image image-small"
        alt="Notifications panel"
        src={process.env.PUBLIC_URL + "/userguide/46792866.png"}
      />
      <span className="image-caption">Notifications panel</span>

      <p>
        When clicking on the notification, the user is automatically redirected
        to the respective request details pop-up, in the
        <strong> Approval Queues</strong> menu.
      </p>
      <ul>
        <li>
          <p>
            If the request has been <em>rejected</em>, no update is performed
            and the requester can consult the comment left by the approver in
            the <strong>Approval Queues</strong> menu.
          </p>
        </li>
        <li>
          <p>
            If the request has been <em>approved</em>, it is sent to the update
            queue, and the user will be notified about the progress status and
            when the process is finished (completed with success or failed) in
            the <strong>Notifications </strong>panel. When clicking on the
            notification, the user is automatically redirected to the respective
            process log, in the <strong>Admin logs</strong> menu.
          </p>
        </li>
      </ul>

      <img
        className="image"
        alt="Approval Queues main table"
        src={process.env.PUBLIC_URL + "/userguide/46792890.png"}
      />
      <span className="image-caption">Approval Queues main table</span>

      <img
        className="image image-small"
        alt="Notifications panel"
        src={process.env.PUBLIC_URL + "/userguide/46792854.png"}
      />
      <span className="image-caption">Notifications panel</span>

      <h2 id="UserManual-TW-MDM-PMU(Requesterrole)-ApprovalQueues">
        Approval Queues
      </h2>
      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-ApprovalQueues-MainScreen">
        Approval Queues - Main Screen
      </h3>
      <p>
        All update requests are registered in the
        <strong> Approval Queues. </strong>Information related to the requests,
        such as the Change Request (which fields were requested to be changed),
        the request date, the request issuer and approver (if the issuer has the
        Requester role), the status of the request and a comment if the request
        has been rejected. By clicking on the “<strong>+</strong>” button in the
        Details column, it will open the
        <strong> Request Details </strong>pop-up.
      </p>
      <img
        className="image"
        alt="Approval Queues main table"
        src={process.env.PUBLIC_URL + "/userguide/46957212.png"}
      />
      <span className="image-caption">Approval Queues main table</span>

      <h4 id="UserManual-TW-MDM-PMU(Requesterrole)-AdvancedFiltering">
        Advanced Filtering
      </h4>
      <p>
        Advanced filtering can be applied in the search bar in the headers or by
        clicking on the filter icon on each table header.
      </p>

      <img
        className="image"
        alt="Search bar, advanced filtering and clean filter icon."
        src={process.env.PUBLIC_URL + "/userguide/46858397.png"}
      />
      <span className="image-caption">
        Search bar, advanced filtering and clean filter icon.
      </span>

      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-RequestDetails">
        Request Details
      </h3>
      <p>
        In order to display the following information, the user must click on
        the “<strong>+</strong>” button in the Details column to open the
        <strong> Request Details </strong>pop-up:
      </p>
      <ul>
        <li>
          <p>Request ID and Issuer (Requester username)</p>
        </li>
        <li>
          <p>Product Number and Local Description</p>
        </li>
        <li>
          <p>Mold Composition</p>
        </li>
        <li>
          <p>Field(s) updated and the previous and new value(s)</p>
        </li>
      </ul>
      <img
        className="image"
        alt="Request Details pop-up"
        src={process.env.PUBLIC_URL + "/userguide/46858319.png"}
      />
      <span className="image-caption">Request Details pop-up</span>

      <h2 id="UserManual-TW-MDM-PMU(Requesterrole)-AdminLogs">Admin Logs</h2>
      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-AdminLogs-Mainscreen">
        Admin Logs - Main screen
      </h3>
      <p>
        To access to the Product Mass Update Admin logs, select
        <strong> Admin Logs </strong>on the lateral bar menu.
      </p>
      <p>
        All update requests that have been approved by the Approver are sent to
        the Admin Logs. Each row of the datatable corresponds to an unique
        Product Mass Update request.
      </p>
      <p>
        As a new request is created, it is sent to the update queue and set as “
        <strong>Enqueued</strong>” status. When the update starts, status is set
        to “<strong>In Progress</strong>”, until the completion of the process,
        where the status changes to “<strong>Completed</strong>” if run with
        success, or “<strong>Failed</strong>” if any error occured.
      </p>
      <p>Other information is displayed, such as:</p>
      <ul>
        <li>
          <p>Product Mass Update ID and Name</p>
        </li>
        <li>
          <p>
            <strong>Change</strong>: which field(s) where updated
          </p>
        </li>
        <li>
          <p>
            <strong>Start and End</strong>: start and end date and hour of the
            update process
          </p>
        </li>
        <li>
          <p>
            <strong>User</strong>: username of the Request Issuer
          </p>
        </li>
        <li>
          <p>
            <strong>Status</strong>: Enqueued, In Progress, Completed or Failed
          </p>
        </li>
      </ul>

      <img
        className="image"
        alt="Admin Logs main screen"
        src={process.env.PUBLIC_URL + "/userguide/46792925.png"}
      />
      <span className="image-caption">Admin Logs main screen</span>

      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-AdvancedFiltering.1">
        Advanced Filtering
      </h3>
      <p>
        Logs can be filtered by using the <em>Year </em>filter in the top-right
        of the page or the <em>Search</em> input in the top-right of the table.
      </p>

      <img
        className="image"
        alt="Year filter and Search bar"
        src={process.env.PUBLIC_URL + "/userguide/46793009.png"}
      />
      <span className="image-caption">Year filter and Search bar</span>

      <p>
        Advanced filtering can be applied in the search bar in the headers or by
        clicking on the filter icon on each table header.
      </p>

      <img
        className="image"
        alt="Simple filtering option"
        src={process.env.PUBLIC_URL + "/userguide/46792931.png"}
      />
      <span className="image-caption">Simple filtering option</span>

      <img
        className="image"
        alt="Advanced filtering option"
        src={process.env.PUBLIC_URL + "/userguide/46792937.png"}
      />
      <span className="image-caption">Advanced filtering option</span>

      <p>
        Filters can be reset and cleaned by clicking on the
        <strong> Clean Filter</strong> icon on the top-right of the page.
      </p>
      <p>
        The <strong>refresh </strong>button will update the data presented in
        the table, but any advanced filtering applied will be reset to default.
      </p>

      <img
        className="image"
        alt="Clean Filter icon + Refresh button"
        src={process.env.PUBLIC_URL + "/userguide/47087672.png"}
      />
      <span className="image-caption">Clean Filter icon + Refresh button</span>

      <h3 id="UserManual-TW-MDM-PMU(Requesterrole)-AdminLogDetails">
        Admin Log Details
      </h3>
      <p>
        <strong>Log Details</strong> can be obtained by clicking on the “
        <strong>+</strong>” button in the <strong>Admin Logs</strong>
        <strong> Details </strong>column.
      </p>
      <p>The following information is displayed in the pop-up:</p>
      <ul>
        <li>
          <p>In the header:</p>
          <ul>
            <li>
              <p>Log ID and Report Name</p>
            </li>
            <li>
              <p>
                <strong>New Value(s)</strong>: what are the new values defined
                for each updated fields
              </p>
            </li>
            <li>
              <p>
                <strong>Status</strong>: Enqueued, In Progress, Completed or
                Failed
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            In the table, where each row represents a subset of the update job:
          </p>
          <ul>
            <li>
              <p>
                <strong>Molds</strong>: which molds are composing the products
              </p>
            </li>
            <li>
              <p>
                <strong>Status</strong>: Enqueued, Started, Completed or Failed
              </p>
            </li>
            <li>
              <p>
                <strong>Exception Type</strong>: if the status is Failed, the
                error type is specified
              </p>
            </li>
          </ul>
        </li>
      </ul>

      <img
        className="image"
        alt="Log Details pop-up"
        src={process.env.PUBLIC_URL + "/userguide/46792943.png"}
      />
      <span className="image-caption">Log Details pop-up</span>
    </div>
  );
};

export default UserGuideBase;
