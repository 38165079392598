import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import useAxios from "../utils/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import { getNewValuesArray } from "../helpers";
import { Link } from "react-router-dom";
import TablePagination from "./TablePagination";
import { FilterChangedEvent } from "ag-grid-community";

const AdminLogDetails = ({ logId }: { logId: string }) => {
  const axios = useAxios();
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [log, setLog] = useState<any>(null);

  const fetchLogAsync = async () => {
    setIsLoading(true);
    try {
      const responseHeader = await axios.get(`pmu-admin-logs/${logId}/`);
      const responseTable = await axios.get(`pmu-admin-logs/${logId}/details`);
      if (responseTable.status === 200 && responseHeader.status === 200) {
        setLog(responseHeader.data);
        setRowData(responseTable.data);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchLogAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnDefs = useMemo(
    () => [
      { field: "molds", flex: 2 },
      { field: "status" },
      { field: "exception", headerName: "Exception Type" },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      filterParams: { buttons: ["reset"] },
      sortable: true,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      suppressMenu: true,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    []
  );

  const onPaginationChanged = useCallback(() => {
    if (gridRef.current?.api) {
      setPageSize(gridRef.current.api.paginationGetPageSize());
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridRef.current.api.paginationGetTotalPages());
      setTotalEntries(gridRef.current.api.getDisplayedRowCount());
    }
  }, [gridRef]);

  const handleTableSearch = (e: any) => {
    const searchString = e.target.value.toLowerCase().trim();
    gridRef.current?.api.setQuickFilter(searchString);
  };

  const onFilterChanged = useCallback((event: FilterChangedEvent) => {
    event.api?.getDisplayedRowCount() === 0
      ? event.api.showNoRowsOverlay()
      : event.api.hideOverlay();
  }, []);

  const overlayNoRowsTemplate = useMemo(
    () => "<span>No results found with these criteria<span>",
    []
  );

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div></div>
        <h3 className="h5 mb-2">Log Details</h3>
        <Link to="/admin" className="icon-button close-cross">
          <FontAwesomeIcon icon={faClose} />
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-start mb-2">
        {log && (
          <>
            <div>
              Log ID: {log.id} - Report Name: {log.name}
              <br />
              New Value(s):
              <br />
              <ul>
                {getNewValuesArray(log).map((newValue: string) => (
                  <li key={newValue}>{newValue}</li>
                ))}
              </ul>
            </div>
            <p>Status: {log.status}</p>
          </>
        )}
      </div>
      <div
        id="pp_search_filter"
        className="dataTables_filter table-top-search d-flex justify-content-end "
      >
        <label>
          Search:
          <input
            onChange={handleTableSearch}
            type="search"
            className="table-top-input"
            placeholder=""
            aria-controls="pp_search"
          />
        </label>
      </div>
      <div className="table-responsive">
        <div className="ag-theme-alpine">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <p>Loading Data... </p>
            </div>
          ) : (
            <>
              <AgGridReact
                domLayout="autoHeight"
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                suppressRowClickSelection
                pagination={true}
                paginationPageSize={10}
                onPaginationChanged={onPaginationChanged}
                unSortIcon
                suppressPaginationPanel
                onFilterChanged={onFilterChanged}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
              ></AgGridReact>
            </>
          )}
        </div>
        <TablePagination
          gridRef={gridRef}
          pageSize={pageSize}
          currentPage={currentPage}
          totalPages={totalPages}
          totalEntries={totalEntries}
        />
      </div>
    </>
  );
};

export default AdminLogDetails;
