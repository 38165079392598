import { useMemo, useState, useEffect, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import ReactModal from "react-modal";
import MoldCompModal from "./MoldCompModal";
import MoldCompCellRenderer from "./MoldCompCellRenderer";
import ChangesCellRenderer from "./ChangesCellRenderer";
import CustomTooltip from "./CustomTooltip";

import Spinner from "../components/Spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilterCircleXmark,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";

import TablePagination from "./TablePagination";
import SelectAllCellRenderer from "./SelectAllCellRenderer";
import { useOutletContext } from "react-router-dom";
import { FilterChangedEvent } from "ag-grid-community";

const PMUTable = ({
  gridRef,
  onSelectionChanged,
  isFirstSearch,
  filterObject,
}: {
  gridRef: any;
  onSelectionChanged: any;
  isFirstSearch: boolean;
  filterObject: any;
}) => {
  const { filteredProducts, isProductsLoading, filterProducts } =
    useOutletContext<any>();
  const [rowData, setRowData] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [floatingFilter, setFloatingFilter] = useState(null);
  const [currentProduct, setCurrentProduct] = useState();
  const closeModal = () => {
    setShowModal(false);
  };

  const handeleMoldDetails = (currentProduct: any) => {
    setCurrentProduct(currentProduct);
    setShowModal(true);
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "SKU nº",
        maxWidth: 140,
        minWidth: 120,
        sort: "asc" as const,
        cellRenderer: (params: any) => {
          const pathToNavigate = `${
            process.env.REACT_APP_PMGI_APP_URL
          }/product-information/?productnumber=${
            params.data.id
          }&sessid=${sessionStorage.getItem("sessid")}`;
          return (
            <>
              <a
                className="text-decoration-underline fw-bold"
                href={pathToNavigate}
                target="_blank"
              >
                {`11 ${params.data.id}`}
              </a>
            </>
          );
        },
      },
      {
        field: "mold_qty",
        headerName: "Component qty.",
        maxWidth: 140,
        minWidth: 120,
        filter: "agNumberColumnFilter",
        cellRenderer: MoldCompCellRenderer,
        cellRendererParams: {
          handeleMoldDetails,
        },
      },
      {
        field: "gp_name",
        headerName: "Global Product",
        flex: 1.5,
        minWidth: 135,
      },
      {
        field: "tcc_name",
        headerName: "TCC",
        flex: 1.5,
        minWidth: 135,
      },
      {
        field: "name",
        headerName: "Local Description",
        flex: 1.5,
        minWidth: 135,
      },
      {
        valueGetter: (params: any) =>
          params.data.last_change ? params.data.last_change : "None",
        maxWidth: 140,
        minWidth: 120,
        headerName: "Changes",
        tooltipField: "last_change",
        cellRenderer: ChangesCellRenderer,
        getQuickFilterText: () => "",
      },
      {
        field: "inventory",
        flex: 1,
        minWidth: 120,
        valueGetter: (params: any) => (params.data.inventory ? "Yes" : "No"),
        getQuickFilterText: () => "",
      },
      {
        field: "select",
        headerName: "Select",
        maxWidth: 100,
        checkboxSelection: true,
        floatingFilterComponent: SelectAllCellRenderer,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      minWidth: 100,
      filter: true,
      filterParams: { buttons: ["reset"] },
      sortable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      floatingFilter: true,
      suppressMenu: true,
      suppressMovable: true,
      tooltipComponent: CustomTooltip,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    []
  );

  const onPageSizeChanged = useCallback(
    (e: any) => {
      gridRef.current?.api.paginationSetPageSize(Number(e.target.value));
      setPageSize(Number(e.target.value));
    },
    [gridRef]
  );

  const onPaginationChanged = useCallback(() => {
    if (gridRef.current?.api) {
      setPageSize(gridRef.current.api.paginationGetPageSize());
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridRef.current.api.paginationGetTotalPages());
      setTotalEntries(gridRef.current?.api?.getDisplayedRowCount());
    }
  }, [gridRef]);

  const handleTableSearch = (e: any) => {
    const searchString = e.target.value.toLowerCase().trim();
    gridRef.current?.api.setQuickFilter(searchString);
  };

  const refreshTable = () => {
    filterProducts(filterObject);
  };

  const clearAllFilters = useCallback(() => {
    gridRef?.current?.api.setFilterModel(null);
  }, [gridRef]);

  const onFilterChanged = (event: FilterChangedEvent) => {
    gridRef?.current?.api.deselectAll();
    const filter = gridRef?.current?.api.getFilterModel();
    if (filter) {
      setIsFiltered(Object.keys(filter).length !== 0);
      setFloatingFilter(filter);
    }
    event.api?.getDisplayedRowCount() === 0
      ? event.api.showNoRowsOverlay()
      : event.api.hideOverlay();
  };

  const overlayNoRowsTemplate = isFirstSearch
    ? "<span><b>Please use the search field(s) above to display data<b><span>"
    : "<span>No results found with these criteria<span>";

  const onGridReady = () => {
    if (floatingFilter) {
      gridRef.current?.api.setFilterModel(floatingFilter);
    }
  };

  useEffect(() => {
    ReactModal.setAppElement("#root");
    setRowData(filteredProducts);
  }, [filteredProducts]);

  return (
    <>
      <ReactModal
        isOpen={showModal}
        className="custom-modal mold-comps"
        overlayClassName="custom-overlay"
      >
        <MoldCompModal handleCloseModal={closeModal} product={currentProduct} />
      </ReactModal>
      <div className="d-flex justify-content-between table-top-container">
        <div className="d-flex align-items-center">
          <label className="d-inline-block">
            Show
            <select
              onChange={onPageSizeChanged}
              className="table-top-input"
              id="page-size"
              value={pageSize}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries
          </label>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div
            id="pp_search_filter"
            className="dataTables_filter table-top-search"
          >
            <label>
              Search:
              <input
                onChange={handleTableSearch}
                type="search"
                className="table-top-input"
                placeholder=""
                aria-controls="pp_search"
              />
            </label>
          </div>
          <button
            className="icon-button ml-3"
            onClick={refreshTable}
            disabled={!filteredProducts?.length}
          >
            <FontAwesomeIcon size="lg" icon={faRotate} />
          </button>
          {/* <button
            className="icon-button ml-3 mr-3"
            onClick={clearAllFilters}
            disabled={!isFiltered}
          >
            <FontAwesomeIcon size="lg" icon={faFilterCircleXmark} />
          </button> */}
        </div>
      </div>
      <div className="ag-theme-alpine">
        {isProductsLoading ? (
          <Spinner />
        ) : (
          <AgGridReact
            domLayout="autoHeight"
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection
            pagination={true}
            paginationPageSize={pageSize}
            rowSelection={"multiple"}
            onGridReady={onGridReady}
            onPaginationChanged={onPaginationChanged}
            onFilterChanged={onFilterChanged}
            onSelectionChanged={onSelectionChanged}
            tooltipShowDelay={0}
            tooltipHideDelay={8000}
            unSortIcon
            suppressPaginationPanel
            overlayNoRowsTemplate={overlayNoRowsTemplate}
          ></AgGridReact>
        )}
      </div>
      <TablePagination
        gridRef={gridRef}
        pageSize={pageSize}
        currentPage={currentPage}
        totalPages={totalPages}
        totalEntries={totalEntries}
      />
    </>
  );
};

export default PMUTable;
