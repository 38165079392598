import AdminLogsTable from "../components/AdminLogsTable";
import AdminLogDetails from "../components/AdminLogDetails";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import Select from "react-select";
import { mapYearOptions, currentYear } from "../helpers";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import useAxios from "../utils/useAxios";
import Spinner from "../components/Spinner";

const AdminPage = () => {
  const authCtx = useContext(AuthContext);
  const { user } = useOutletContext<any>();
  const hasPermission = authCtx?.hasPermission;
  const axios = useAxios();

  const navigate = useNavigate();
  const { logId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [adminLogs, setAdminLogs] = useState<any[]>([]);
  const [floatingFilter, setFloatingFilter] = useState(null);
  const [yearSelected, setYearSelected] = useState({
    value: currentYear,
    label: currentYear
  });

  const fetchLogsAsync = async (year = yearSelected.value) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `pmu-admin-logs/?year=${year}&limit=1000`
      );
      setAdminLogs(response.data.results);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const onYearChange = (e: any) => {
    if (e) {
      setYearSelected(e);
      fetchLogsAsync(e.value);
    }
  };

  useEffect(() => {
    if (!hasPermission("api.logs_pmu_queue")) return navigate("/no-access");
    fetchLogsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, hasPermission, navigate]);

  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h5 className="my-1 text-primary">Admin Logs</h5>
          {logId ? null : (
            <div className="d-flex align-items-center">
              <label className="m-0 mr-1">Year:</label>
              <Select
                className="year-select"
                classNamePrefix="react-select"
                onChange={onYearChange}
                options={mapYearOptions()}
                value={yearSelected}
                components={{
                  IndicatorSeparator: () => null
                }}
              />
            </div>
          )}
        </div>
        <div className="card-body">
          {isLoading ? (
            <Spinner />
          ) : logId ? (
            <AdminLogDetails logId={logId} />
          ) : (
            <AdminLogsTable
              data={adminLogs}
              refreshData={fetchLogsAsync}
              floatingFilter={floatingFilter}
              setFloatingFilter={setFloatingFilter}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
