import useAxios from "../utils/useAxios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheck,
  faThumbsUp,
  faThumbsDown,
  faExclamation,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";
const Notification = ({ notification }: { notification: any }) => {
  const axios = useAxios();
  const navigate = useNavigate();

  const { id, description, created_at, queue_status, queue, type } =
    notification;
  const dateFormatted = new Date(created_at).toLocaleString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric"
  });

  const handleClick = async () => {
    const response = await axios({
      method: "put",
      url: `/notifications/${id}/`,
      data: {
        is_read: true
      }
    });
    if (response.status === 200) {
      navigate(
        type === "PMU_APPROVER_QUEUE"
          ? `/approval-queues/${queue}`
          : `/admin/${queue}`
      );
    }
  };

  return (
    <div
      className="dropdown-item d-flex align-items-center"
      onClick={handleClick}
    >
      <div className="mr-3">
        {queue_status === "Approved" ? (
          <div className="icon-circle success">
            <FontAwesomeIcon icon={faThumbsUp} size="lg" />
          </div>
        ) : queue_status === "Rejected" ? (
          <div className="icon-circle fail">
            <FontAwesomeIcon icon={faThumbsDown} size="lg" />
          </div>
        ) : queue_status === "Completed" ? (
          <div className="icon-circle bordered success">
            <FontAwesomeIcon icon={faCheck} size="sm" />
          </div>
        ) : queue_status === "In Progress" ? (
          <div className="icon-circle">
            <FontAwesomeIcon icon={faSpinner} size="lg" />
          </div>
        ) : queue_status === "Pending" ? (
          <div className="icon-circle bordered pending">
            <FontAwesomeIcon icon={faQuestion} size="sm" />
          </div>
        ) : (
          <div className="icon-circle bordered fail">
            <FontAwesomeIcon icon={faExclamation} size="sm" />
          </div>
        )}
      </div>
      <div>
        <div className="small text-gray-500">{dateFormatted}</div>
        <span
          className="font-weight-bold"
          dangerouslySetInnerHTML={{ __html: description }}
        ></span>
      </div>
    </div>
  );
};

export default Notification;
