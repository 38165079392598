import { createContext, useState, ReactNode, useContext } from "react";
import useAxios from "../utils/useAxios";
import AuthContext from "./AuthContext";

interface AppContextInterface {
  notifications: any[];
  fetchNotifications?: any;
  markAllAsRead?: any;
}

const NotificationContext = createContext<AppContextInterface | null>(null);

export default NotificationContext;

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const axios = useAxios();
  const authctx = useContext(AuthContext);

  const fetchNotifications = async () => {
    if (authctx?.user) {
      try {
        const response = await axios.get(
          "/notifications/?limit=300&is_read=false"
        );
        if (response.status === 200) setNotifications(response.data?.results);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const markAllAsRead = async () => {
    const response = await axios.put("/notifications/clear-all/");
    if (response.status === 200) setNotifications([]);
  };

  const contextData: AppContextInterface = {
    notifications,
    fetchNotifications,
    markAllAsRead
  };

  return (
    <NotificationContext.Provider value={contextData}>
      {children}
    </NotificationContext.Provider>
  );
};
